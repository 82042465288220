.App {
  text-align: center;
  /* font-family:Arial, Helvetica, sans-serif; */
  font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
  display:flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  line-height: 1.3;
  padding-left: 240px;
}

@media screen and (max-width: 600px) {
  .App {
    padding-left: 0;
  }
  
}
/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Open Sans", sans-serif;
  color: #313030;
}

a {
  color: #ff5821;
  text-decoration: none;
}

a:hover {
  color: #ff7e54;
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway", sans-serif;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
hr {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 0;
  border-top: 2px solid #EEECF0;
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  display: block;
  unicode-bidi: isolate;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.text-center{
  font-size: 26px;
  font-weight: 300;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: #635551;
}

section {
  padding: 60px 0;
  overflow: hidden;
}

.section-bg {
  background-color: #fff9f7;
}

.section-title {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 20px;
  padding-bottom: 0;
  font-family: "Poppins", sans-serif;
  color: #635551;
}
.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.firstContainer {
  padding: 15px ;
  background: #fcfcfc;
  margin-bottom: 40px;
  border-top: #d7d7d7 1px solid;
  justify-content: center;
  /* padding-right: 300px; */
}
.firstContainer h2 {
  font-size: 28px;
  font-weight: 500;
}
.firstContainer ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.firstContainer ol li + li {
  padding-left: 10px;
}
.firstContainer ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #635551;
  content: "/";
}

@media screen and (max-width: 600px) {
  .firstContainer {
    padding: 15px ;
    background: #fcfcfc;
    margin-bottom: 40px;
    border-top: #d7d7d7 1px solid;
    justify-content: center;
    padding-right: 0;
  }
  
}
/* second container */

/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
} */
/*--------------------------------------------------*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*----------------------------buton universal orange----------------------------------*/
.universalButton {
  background: #ffffff;
  box-shadow: 0 3px 7px rgb(255 88 33 / 40%);
  border: 1px solid #ff5821;
  border-radius: 4px;
  color: #ff5821;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  padding: 6px 35px 6px 35px;
  transition-duration: 0.4s;
  transition: none;
  text-decoration: none;
  margin-left: 2px;
  margin-right: 2px;
}
.buttonUniv {
  background-color: white; 
  color: #ff5821; 
  border: 1px solid #ff5821;
}
.buttonUniv:hover {
  background-color: #ff5821;
  color: white;
}
.universalButtonGreen {
  background: #ffffff;
  box-shadow: 0 3px 7px rgba(8, 94, 31, 0.705);
  border: 1px solid #198754;
  border-radius: 4px;
  color: #198754;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  padding: 6px 15px 6px 15px;
  transition-duration: 0.4s;
  transition: none;
  text-decoration: none;
  margin-left: 2px;
  margin-right: 2px;
}
.buttonUnivGreen {
  background-color: white; 
  color: #198754; 
  border: 1px solid #198754;
}
.buttonUnivGreen:hover {
  background-color: #198754;
  color: white;
}
.thButtonSpace {
  width: 50px;
}

/*----------------------------input----------------------------------*/
.inputLabel.input-group-text {
  color: #635551;
  width: 130px;
  border: 1px solid #635551;
}
.inputLabel.form-control {
  width: 130px;
  color: #635551;
}
.inputLabel.form-control:hover  {
  background-color: #ffffff;
  color: #635551;
  border: 1px solid #ff5821;
}
.inputLabel.form-control:focus  {
  background-color: #ffffff;
  color: #635551;
  border: 1px solid #635551;
}
