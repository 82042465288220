.icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-top: 25px;
    color:#313031;
}
.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #ff5825;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    /* font-weight: 600; */
}

.textStyle {
    color: #ffffff;
    font-weight: 400;
    font-size: 14px;
}
.row .col span {
    font-size: 16px;
    font-weight: 500;
    color: #ff5825;
    font-family: "Open Sans", sans-serif;
}

.theme {
    padding: 14px;
    border-radius: 15px;
    border: 1px solid;
    margin: 0 0 0 15px;
    background-color: #000000;
    color: #ffffff;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    background-position: center;
    max-width: inherit;
    width: 250px;
    height: 250px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  }

.uiContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 20px;
    margin-bottom: auto;
    flex-direction: column;
    /* border: 1px solid #000000; */
}
.uiContent {
    align-items: center; 
    justify-content: center;
    /* border: 1px solid #c71e1e; */
}

/*telefoane*/
@media only screen and (max-width: 600px) {
    .uiContainer {
        flex-direction: column; /* Schimbă direcția de afișare pe coloană pentru ecrane mici */
        margin: 10px;
    }    
    .uiContent {
        width: 100%; 
    }
    .uiTitle h1 {
        font-size: 28px;
    }
}
/* Media query pentru tablete */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .uiContainer {
        margin: 15px;
    }
    .uiContent {
        width: 90%;
    }
    .uiTitle h1 {
        font-size: 24px;
    }
}
 
/* ///////////////////////////////////  ui nou ///////////////////////////////////////*/
.hotel-services-container {
    display: flex;
    flex-direction: column;
    /* min-height: 100vh;  */
    justify-content: flex-end; /* Asigură-te că bara de meniu este la fund */
    margin-bottom: 120px;
  }
  
  .hotel-services-menu {
    padding: 10px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #ddd;
    z-index: 10; /* Asigură-te că menu-ul nu este acoperit */
    position: fixed; /* Fixăm meniul pentru a nu se mișca la scroll */
    top: 30%; /* Ridicăm meniul puțin sub bara de meniu */
    left: 0;
    right: 0;
    width: 100%;
    height: 100%; /* Înălțimea meniului */
    max-height: 100vh; /* Limităm înălțimea meniului */
    overflow-y: auto; /* Permitem scroll dacă este nevoie */
  }
  
  .services-list {
    margin-top: 20px;
  }
  
  .message-form {
    padding: 10px;
    background-color: #f4f4f4;
    border-bottom: 1px solid #ddd;
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
    width: 100%;
    /* height: 100%; */
    max-height: 100vh;
    overflow-y: auto;
    z-index: 10;
    box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
  }
  
  .bottom-bar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 10px;
    border-top: 1px solid #ddd;
    z-index: 50; /* Asigură-te că bara este deasupra altui conținut */
  }
  
  .bottom-bar button {
    width: 33%;
  }
  