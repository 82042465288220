/* index.css */

/* General Styling */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
  }
  
  /* .firstContainer {
    padding-right: 240px;
  } */
  
  .containerComp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
  
  .formContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .room {
    width: 120px; /* Am crescut lățimea pentru a acomoda textul suplimentar */
    height: 120px; /* Am crescut înălțimea pentru a acomoda textul suplimentar */
    display: flex;
    flex-direction: column; /* Aranjare verticală a textului */
    align-items: center;
    justify-content: center;
    border: 2px solid; /* Bordura dinamică */
    border-radius: 10px;
    margin: 10px;
    text-align: center;
    background-color: white; /* Fundal alb */
    color: black; /* Text negru pentru contrast */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative; /* Necesare pentru poziționarea bulinei */
    transition: transform 0.2s, border-color 0.2s;
  }
  
  .room:hover {
    transform: scale(1.05);
  }
  
  .room.free {
    border-color: green; /* Verde pentru camere libere fără notificări */
  }
  
  .room.notification {
    border-color: red; /* Roșu pentru camere cu notificări */
  }
  
  .hotel-name {
    font-weight: bold;
    font-size: 0.9em; /* Dimensiune text ajustată pentru numele hotelului */
    margin-bottom: 4px; /* Spațiu sub numele hotelului */
  }
  
  .room-name {
    font-weight: bold;
    font-size: 1.1em; /* Dimensiune text ajustată pentru denumirea camerei */
    margin-bottom: 8px; /* Spațiu sub denumirea camerei */
  }
  
  .room-sesizari-container {
    position: absolute;
    top: 8px;
    right: 8px;
  }
  
  .room-sesizari-bulina {
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Asigură că popup-ul este deasupra altor elemente */
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 300px;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
  }
  
  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    cursor: pointer;
  }
  
  .popup h2 {
    margin: 0;
    font-size: 1.2em;
  }
  
  .popup ul {
    list-style: none;
    padding: 0;
  }
  
  .popup li {
    margin-bottom: 10px;
  }
  
  .error {
    color: red;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
  }
  .noRoomsMessage {
    text-align: center;
    font-size: 16px; 
    margin-top: 20px;
    
}

.addRoomMessage {
    text-align: center;
    color: #ff5821;
    font-size: 16px;
    margin-top: 10px;
}

.universalButton {
  background: #ffffff;
  box-shadow: 0 3px 7px rgb(255 88 33 / 40%);
  border: 1px solid #ff5821;
  border-radius: 4px;
  color: #ff5821;
  cursor: pointer;
  display: inline-block;
  font-size: 16px;
  font-weight: 400;
  font-family: "Raleway", sans-serif;
  font-weight: 600;
  padding: 6px 35px 6px 35px;
  transition-duration: 0.4s;
  transition: none;
  text-decoration: none;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 20px;
}
.universalButton:hover {
  background-color: #ff5821;
  color: rgb(255, 255, 255);
  border: 1px solid #ff5821;
  border-radius: 4px;
}

/*----------------------------chat----------------------------------*/

/* Container principal */

/* Chat fix pe dreapta desktop */
.chatContainer {
  width: 270px;
  height: 100%;
  position: fixed;
  right: 20px;
  top: 100px;
  background: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
}

/* Butonul de chat pe mobil */
.chatButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #1976d2;
  color: white;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.chatButton:hover {
  background-color: #1565c0;
}

@media (min-width: 768px) {
  /* Chat fix pe dreapta desktop */
  .chatContainer {
    width: 300px;
    height: 100%;
    position: fixed;
    right: 20px;
    top: 100px;
    background: white;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    z-index: 1000;
  }

  /* Butonul de chat pe mobil */
  .chatButton {
    display: none;
  }

  .firstContainer {
    padding: 0;
    margin-left: 0;
  }
}