.containerForm {
    /* position: relative; */
    margin: auto;
    /* display: flex;
    -webkit-box-pack: center; */
    justify-content: center;
    padding: 24px;
    background-color: rgba(243, 246, 249, 0.6);
    border: 1px solid rgb(229, 234, 242);
    border-radius: 4px;
    max-width: 90%;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root{
    margin-bottom: 14px;
    margin-right: 14px;
}

/* .buttonContainer {
    align-items: center;
    justify-content: flex-end;
    max-width: 300px;
    margin: auto;
} */
.firstContainerRestaurants {
    padding: 15px ;
    padding-top: 25px;
    background: #fcfcfc;
    margin-bottom: 10px;
    border-top: #d7d7d7 1px solid;
    justify-content: center;

  }

.ContainerRestaurants {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.textAreaStyled {
    width: 100%;
    max-width: 600px;
    height: 150px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical; /* Permite redimensionarea verticală */
}

/* Stiluri generale pentru react-datepicker */
.react-datepicker {
    border-radius: 8px;
    border: 1px solid #ddd; /* Schimbă bordura */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adaugă umbră */
    font-family: 'Arial', sans-serif; /* Stilizează textul */
    background-color: #fff; /* Fundal alb */
    overflow: hidden; /* Previne depășirea */
    display: flex;
    flex-direction: row; /* Asigură layout pe orizontală */
  }
  
  .react-datepicker__header {
    background-color: #ff5825; /* Fundal pentru antet */
    color: #fff; /* Text alb */
    border-bottom: 1px solid #ddd; /* Bordura inferioară */
    padding: 0.5rem;
    border-top-left-radius: 8px;
    /* border-top-right-radius: 8px; */
    text-align: center; /* Centrare text */
  }

  .react-datepicker__month-container {
    display: flex;
    flex-direction: column; /* Layout vertical pentru luna curentă */
  }
  
  .react-datepicker__time-container {
    flex-grow: 1; /* Asigură că timpul se întinde pe lățimea disponibilă */
    min-width: 120px; /* Asigură o lățime minimă pentru selectorul de timp */
  }
  
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #374151; /* Culoare text */
    width: 2.5rem; /* Lățime fixă pentru zile */
    line-height: 2.5rem; /* Înălțime fixă */
    text-align: center; /* Centrare text */
    margin: 0.2rem; /* Spațiu între zile */
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: #ff5825; /* Culoare pentru zile selectate */
    color: #fff; /* Text alb */
    border-radius: 50%; /* Rotunjire completă */
  }
  
  .react-datepicker__day--today {
    font-weight: bold;
    background-color: #ffe6d5; /* Culoare mai deschisă pentru ziua curentă */
    border-radius: 50%; /* Rotunjire completă */
  }
  
  .react-datepicker__day:hover {
    background-color: #ff7d4b; /* Culoare la hover */
    color: #fff; /* Text alb */
  }
  
  /* Stiluri pentru selectorul de timp */
  .react-datepicker__time-container .react-datepicker__time {
    background: #fff; /* Fundal alb pentru selectorul de timp */
    border-left: 1px solid #ddd; /* Bordura separatorului */
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    margin: 0;
    padding: 0;
    list-style: none;
    /* height: calc(100% - 1rem); Ajustează înălțimea pentru a se potrivi */
    overflow-y: scroll; /* Adaugă scroll */
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li {
    padding: 0.5rem; /* Adaugă padding */
    text-align: center; /* Centrare text */
    cursor: pointer; /* Cursor pointer */
  }
  
  /* Stiluri pentru hover și elemente selectate în selectorul de timp */
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li:hover {
    background-color: #ff7d4b; /* Culoare la hover */
    color: #fff; /* Text alb */
  }
  
  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #ff5825; /* Culoare pentru ore selectate */
    color: #fff; /* Text alb */
  }
  
  .react-datepicker__input-container input {
    padding: 0.5rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    width: 100%; /* Lățime completă */
    font-size: 1rem; /* Mărimea textului */
  }
  .react-datepicker-time__header {
    margin-top: 0;
    color: #ffffff;
    font-weight: bold;
    font-size: 0.944rem;
}
.react-datepicker__current-month {
    color: #ffffff; /* Schimbă culoarea textului la alb */
  }

  .errorInput {
    border: 1px solid red;
}

.error {
  color: red;
  font-size: 14px;
}

.form-control {
  padding: 8px 12px;     /* Adaugă padding pentru un spațiu mai mare în interiorul inputului */
  font-size: 14px;        /* Dimensiunea textului în input */
  border: 1px solid #ccc; /* Contur gri pentru input */
  border-radius: 4px;     /* Colțuri rotunjite */
  background-color: #f9f9f9; /* Fundal deschis */
}

.form-control:focus {
  border-color: #ff5825;  /* Culoare verde la focus */
  box-shadow: 0 0 5px #ff5825; /* Umbră verde când inputul este selectat */
}
